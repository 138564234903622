import axios, { AxiosError, AxiosResponse } from 'axios';
import {REACT_APP_ACCOUNT_API_URL,REACT_APP_DRIVE_URL,REACT_APP_API_URL, ProjectUrl} from "../envConfig"

const _get = require('lodash/get');

let displayAxiosInstance;
let driveAxiosInstance;
let accountAxiosInstance;

export const http = (APIName, accountToken,displayToken) => {
  let axiosInstance;

  switch (APIName) {
    case 'Account': 
      if (!accountAxiosInstance || accountToken) {
        const tokenForAccount = accountToken ? accountToken : localStorage.getItem('accountAccessToken');
        accountAxiosInstance = axios.create({
          baseURL: REACT_APP_ACCOUNT_API_URL,
          headers: {
            'Authorization': `Bearer ${tokenForAccount}`,
          },
        });
      }
      axiosInstance = accountAxiosInstance;
      break;
    
    case 'Drive':
      if (!driveAxiosInstance) {
        driveAxiosInstance = axios.create({
          baseURL: REACT_APP_DRIVE_URL,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken') }`,
          },
        });
      }
      axiosInstance = driveAxiosInstance;
      break;

    default:
      if (!displayAxiosInstance || displayToken) {
        const token = displayToken ? displayToken : localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : "";
        displayAxiosInstance = axios.create({
          baseURL: REACT_APP_API_URL,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
      }
      axiosInstance = displayAxiosInstance;
      break;
  };

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const statusCode = _get(error, 'response.status', '');
      if (statusCode === 401) {
        localStorage.clear();
        const path = window.location.pathname.split('/').length > 1 ? window.location.pathname.split('/')[1] : 'dashboard';
        window.location.href = `${ProjectUrl[0].url}?continue=display_site+${path}`;
      }
      return error.response || {};
    }
  );
  
  return {
    get: (endpoint, data) => axiosInstance.get(endpoint, data).then((res) => res),
    post:(endpoint, data, config) => axiosInstance.post(endpoint, data, config).then((res) => res),
  }
  
};