import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <App />

);

