import AppsIcon from '@mui/icons-material/Apps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Popover from '@mui/material/Popover';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faListCheck, faDna, faTicket } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { http } from '../Utils/api';
import { ImageSizeVariants, getImageFromDrive } from '../Services/drive';
import { eFileModule } from '../Utils/constants';
import { ProjectUrl } from '../envConfig';

export default function MainHeader({ toggleSidebar, isSidebarOpen }) {
  /**Helpers */
  const navigate = useNavigate();
  const __location = useLocation()
  const pathName = __location.pathname;


  let [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get('key');
  const logout = searchParams.get('logout');
  const continueSegment = searchParams.get('continue');

  /**Mutable State */
  const [showLoader, setShowLoader] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [fullName, setFullName] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [appsAnchor, setAppsAnchor] = useState(null);
  const [organizationsAnchor, setOrganizationsAnchor] = useState(null);
  const open = Boolean(anchorEl);
  const showApps = !!appsAnchor;
  const showOrganizations = !!organizationsAnchor;


  const [filteredOrganizations, setFilteredOrganizations] = useState([]);


  const id = open ? 'profile-dropdown' : undefined;
  const appsDropdownId = showApps ? 'appsDropdown' : undefined;
  const organizationDropdownId = showOrganizations ? 'orgaizationDropdown' : undefined;

  /**Internal Methods */
  const onLogout = () => {
    localStorage.clear();
    window.location.href = `${ProjectUrl[0].url}?logout=0`;
  };

  const onProfileButtonClick = (event) => {
    addClassToBody('profileOpened');
    setAnchorEl(event.currentTarget);
  };

  const onAppsClick = (event) => {
    addClassToBody('appsOpened');
    setAppsAnchor(event.currentTarget);
  };

  const addClassToBody = (className) => {
    document.body.classList.add(className);
  };

  const removeClassFromBody = (className) => {
    setTimeout(() => {
      document.body.classList.remove(className);
    }, 500);
  };

  const onOrganizationClick = (event) => {
    addClassToBody('organizationListOpened');
    setOrganizationsAnchor(event.currentTarget);
  };

  const handleClose = () => {
    removeClassFromBody('profileOpened');
    setAnchorEl(null);
  };

  const handleAppsClose = () => {
    removeClassFromBody('appsOpened');
    setAppsAnchor(null);
  };

  const handleOrganizationsClose = (event) => {
    removeClassFromBody('organizationListOpened');
    setOrganizationsAnchor(null);
  };

  const setAccountToken = async () => {
    try {
      const token = localStorage.getItem('accountAccessToken');
      if (token) {
        setShowLoader(true);
        const response = await http('Account').post('token/set', { token });
        if (response && response.data && response.data.key) {
          return response.data.key;
        }
        setShowLoader(false);
      }
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };
  const getAccountToken = async () => {
    try {
      const response = await http('Account').get('token/get', {
        params: {
          key
        }
      });
      if (response && response.data && response.data.token) {
        localStorage.setItem('accountAccessToken', response.data.token);
        http('Account', response.data.token);
        updateUserProfile();
        getOrganizationList(response.data.token);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserProfile = async () => {
    try {
      const response = await http('Account').post('profile/get_by_id');
      if (response.data) {
        if (response.data.firstName) {
          setFullName(`${response.data.firstName} ${response.data.lastName}`);
        }

        if (response.data.photoGuid) {
          const imageRes = await getImageFromDrive([response.data.photoGuid], ImageSizeVariants._100X100, eFileModule.profilePicture);
          if (imageRes.data && imageRes.data.lstFile && imageRes.data.lstFile.length) {
            setProfilePicture(imageRes.data.lstFile[0].fileURL);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOrganizationList = async (accountToken) => {
    try {
      const data = {
        appId: 3,
      };
      const response = await http('Account', accountToken).post('organization/get_by_appid', data);
      if (response && response.data && response.data.lstOrganization && response.data.lstOrganization.length) {
        // getDisplayToken(accountToken, response.data.lstOrganization);
        updateOrganizationDetails(response.data.lstOrganization);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchOrganizations = async () => {
    try {
      const accountAccessToken = localStorage.getItem('accountAccessToken');
      if (accountAccessToken) {
        const data = {
          appId: 3,
        };
        const response = await http('Account', accountAccessToken).post('organization/get_by_appid', data);
        if (response && response.data && response.data.lstOrganization && response.data.lstOrganization.length) {
          updateOrganizationDetails(response.data.lstOrganization)
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateOrganizationDetails = (organizationList) => {
    setOrganizations(organizationList);
    let savedOrg = localStorage.getItem('selectedOrganization');
    savedOrg = savedOrg ? JSON.parse(savedOrg) : undefined;
    if (savedOrg && savedOrg.OrganizationId && organizationList.some((org) => org.OrganizationId === savedOrg.OrganizationId)) {
      setSelectedOrganization(savedOrg);
      getDisplayToken(savedOrg.OrganizationId);
    } else {
      setSelectedOrganization(organizationList[0]);
      localStorage.setItem('selectedOrganization', JSON.stringify(organizationList[0]));
      getDisplayToken(organizationList[0].OrganizationId);
    }
  };

  const getDisplayToken = async (organizationId) => {
    try {
      const accountAccessToken = localStorage.getItem('accountAccessToken');
      if (accountAccessToken) {
        const data = {
          accountAccessToken,
          organizationId
        };
        const response = await http().post('access_token/get', data);
        if (response && response.data && response.data.accessToken) {
          localStorage.setItem('accessToken', response.data.accessToken);
          http(undefined, undefined, response.data.accessToken);
          setShowLoader(false);
          if (continueSegment) {
            navigate(`/${continueSegment}`);
          }
        }
      }

    } catch (err) {
      console.log(err);
    }
  };
  const onSelectOrg = (org) => {
    setSelectedOrganization(org);
    setOrganizationsAnchor(null);
    setTimeout(() => {
      updateDisplayToken(org);
    }, 0);
  };



  const updateDisplayToken = async (org) => {
    try {
      const accountAccessToken = localStorage.getItem('accountAccessToken');
      if (accountAccessToken) {
        setShowLoader(true);
        const data = {
          accountAccessToken,
          organizationId: org.OrganizationId
        };
        const response = await http().post('access_token/get', data);
        if (response && response.data && response.data.accessToken) {
          localStorage.setItem('accessToken', response.data.accessToken);
          http(undefined, undefined, response.data.accessToken);
          localStorage.setItem('selectedOrganization', JSON.stringify(org));
          setShowLoader(false);
          window.location.reload();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const redirectToWebsite = (url) => {
    window.open(url, '_blank');
  }


  const goToAccount = async () => {
    const key = await setAccountToken();
    redirectToWebsite(`${ProjectUrl[0].url}#/?key=${key}&continue=dashboard`);
  };

  const goToTasks = async () => {
    const key = await setAccountToken();
    redirectToWebsite(`${ProjectUrl[0].url}?key=${key}&continue=dashboard`);
  };

  const goToTicket = async () => {
    const key = await setAccountToken();
    redirectToWebsite(`${ProjectUrl[2].url}#/?key=${key}&continue=dashboard`);
  };

  const goToBiometric = async () => {
    const key = await setAccountToken();
    redirectToWebsite(`${ProjectUrl[1].url}#/?key=${key}&continue=dashboard`);
  };

  const goToActiveReports = async () => {
    const key = await setAccountToken();
    redirectToWebsite(`${ProjectUrl[4].url}#/?key=${key}&continue=dashboard`);
  };

  const goToDisplaySite = async () => {
    const key = await setAccountToken();
    redirectToWebsite(`${ProjectUrl[3].url}#/?key=${key}&continue=dashboard`);
  };


  const onSearchOrganization = (text) => {
    setSearchText(text);
    if (organizations && organizations.length) {
      if (text) {
        const result = organizations.filter((org) => org.organizationName.toLowerCase().includes(text.toLowerCase()));
        setFilteredOrganizations([...result]);
      } else {
        setFilteredOrganizations([...organizations]);
      }
    } else {
      setFilteredOrganizations([]);
    }
  };

  const checkIfUserIsLoggedIn = () => {

    const accessToken = localStorage.getItem('accessToken');
    const accountAccessToken = localStorage.getItem('accountAccessToken');
    if (!accessToken || !accountAccessToken) {
      localStorage.clear();
      // window.location.href = `${ProjectUrl[0].url}?continue=display_site+dashboard`;
    } else {
      updateUserProfile();
      fetchOrganizations();
      if (pathName === '/') {
        navigate('/dashboard');
      }
    }
  };

  /**Effects */
  useEffect(() => {
    if (organizations && organizations.length) {
      if (searchText) {
        const result = organizations.filter((org) => org.organizationName.toLowerCase().includes(searchText.toLowerCase()));
        setFilteredOrganizations([...result]);
      } else {
        setFilteredOrganizations([...organizations]);
      }
    } else {
      setFilteredOrganizations([]);
    }
  }, [organizations]);

  useEffect(() => {
    if (key) {
      getAccountToken();
    } else if (logout !== undefined && logout !== null && !isNaN(Number(logout))) {
      const index = Number(logout);
      localStorage.clear();
      if (ProjectUrl[index + 1]) {
        window.location.href = `${ProjectUrl[index + 1].url}?logout=${index + 1}`;
      } else {
        window.location.href = ProjectUrl[0]?.url;
      }
    } else {
      checkIfUserIsLoggedIn();
    }
  }, []);

  return (
    <div className={`header z-[99] flex justify-between items-center w-full h-[50px] bg-[#262b36] ${isSidebarOpen ? 'pl-0' : 'pl-[14px]'} pr-[20px]`}>
      <div className='flex items-center'>

        {!!selectedOrganization && (
          <div className={`${!isSidebarOpen ? 'hidden' : ''}`}>
            <button className='w-[220px] px-[14px] h-[50px] items-center text-[#ced5e3] hover:text-[#778395] flex gap-x-[5px] justify-between' aria-describedby={organizationDropdownId} onClick={onOrganizationClick}>
              <span className='whitespace-nowrap overflow-hidden text-ellipsis'>{selectedOrganization?.organizationName}</span>
              {!showOrganizations && (<ArrowDropDownIcon sx={{ fontSize: '24px' }} />)}
              {showOrganizations && (<ArrowDropUpIcon sx={{ fontSize: '24px' }} />)}

            </button>

            <Popover
              id={organizationDropdownId}
              open={showOrganizations}
              anchorEl={organizationsAnchor}
              onClose={handleOrganizationsClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className='p-[10px] font-mulish text-[14px] w-[220px]'>

                <input value={searchText} onChange={(e) => onSearchOrganization(e.target.value)} className='searchInp pl-3 pr-2 w-full h-[34px] placeholder:text-[#bdc3d1] placeholder:text-[14px] border border-solid border-[#bdc3d1] text-[14px] text-[#262b36] outline-none mb-[5px]' placeholder=' ' />

                {!!selectedOrganization && (
                  <div className='pl-3 pr-2 w-full h-[34px] bg-[#259dab] text-[14px] text-white flex items-center'>
                    <div className='whitespace-nowrap overflow-hidden text-ellipsis'>
                      {selectedOrganization.organizationName}
                    </div>
                  </div>
                )}

                <div className='w-full border-b-[2px] my-[10px] border-b-solid border-b-[#d8dce3]'></div>


                <div className='flex flex-col max-h-[calc(100vh-200px)] overflow-auto'>
                  {filteredOrganizations.map((org, index) => {
                    return (
                      <div onClick={(e) => onSelectOrg(org)} className='pl-3 cursor-pointer pr-2 py-[2px] w-full min-h-[34px] text-[14px] text-[#2e2d2d] flex items-center border-b border-b-[#d8dce3] last:border-b-0' key={org.OrganizationId}>
                        <div className='break-all'>
                          {org.organizationName}
                        </div>
                      </div>
                    );
                  })}
                </div>


              </div>
            </Popover>
          </div>
        )}

        <div className='flex gap-x-[15px]'>
          <FontAwesomeIcon onClick={(e) => toggleSidebar()} className={`cursor-pointer text-white hover:text-[#9fa8bc] text-[21px] mt-[3px] ml-[9px]`} icon={faBars} />
          <div className='font-open-sans-500 text-[20px] tracking-[0.25px] text-white hover:text-[#9fa8bc] hidden'>Zibma</div>
        </div>
      </div>


      <div className='flex gap-x-[18px]'>

        <div>
          <button className='text-[#ced5e3] hover:text-[#778395]' aria-describedby={appsDropdownId} onClick={onAppsClick}>
            <AppsIcon sx={{ fontSize: '30px' }} className='text-white hover:text-[#778395] cursor-pointer text-[30px]' />
          </button>

          <Popover
            id={appsDropdownId}
            open={showApps}
            anchorEl={appsAnchor}
            onClose={handleAppsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className='p-[10px]'>

              <div className='flex flex-wrap w-[278px]'>

                {/* <div onClick={(e) => goToAccount()} className='flex items-center flex-col gap-[10px] p-[10px] w-[33.33%] hover:bg-[#e6e7e9] group cursor-pointer'>
                    <AssignmentIndIcon className='text-[25px] max-[767px]:text-[20px] text-[#086aaf] group-hover:text-[#242525]' />
                    <span className='text-[#505b72] text-[14px] font-mulish group-hover:text-[#242525]'>Account</span>
                  </div> */}

                <div onClick={(e) => goToTasks()} className='flex items-center flex-col gap-[10px] p-[10px] w-[33.33%] hover:bg-[#e6e7e9] group cursor-pointer'>
                  <FontAwesomeIcon className='text-[25px] max-[767px]:text-[20px] text-[#086aaf] group-hover:text-[#242525]' icon={faListCheck} />
                  <span className='text-[#505b72] text-[14px] font-mulish group-hover:text-[#242525]'>Task</span>
                </div>

                <div onClick={(e) => goToTicket()} className='flex items-center flex-col gap-[10px] p-[10px] w-[33.33%] hover:bg-[#e6e7e9] group cursor-pointer'>
                  <FontAwesomeIcon className='text-[25px] max-[767px]:text-[20px] text-[#086aaf] group-hover:text-[#242525]' icon={faTicket} />
                  <span className='text-[#505b72] text-[14px] font-mulish group-hover:text-[#242525]'>Ticket</span>
                </div>

                <div onClick={(e) => goToBiometric()} className='flex items-center flex-col gap-[10px] p-[10px] w-[33.33%] hover:bg-[#e6e7e9] group cursor-pointer'>
                  <FontAwesomeIcon className='text-[25px] max-[767px]:text-[20px] text-[#086aaf] group-hover:text-[#242525]' icon={faDna} />
                  <span className='text-[#505b72] text-[14px] font-mulish group-hover:text-[#242525]'>Biometric</span>
                </div>

                <div onClick={(e) => goToActiveReports()} className='flex items-center flex-col gap-[10px] p-[10px] w-[33.33%] hover:bg-[#e6e7e9] group cursor-pointer'>
                  <FontAwesomeIcon className='text-[25px] max-[767px]:text-[20px] text-[#086aaf] group-hover:text-[#242525]' icon={faDna} />
                  <span className='text-[#505b72] text-[14px] font-mulish group-hover:text-[#242525] whitespace-nowrap'>Active Reports</span>
                </div>

              </div>

            </div>
          </Popover>


        </div>

        <div className='flex gap-x-[5px] items-center'>
          <img loading='lazy' className='rounded-full w-[30px] h-[30px]' src={profilePicture ? profilePicture : '/images/avatar.jpg'} width={30} height={30} alt='Avatar' />

          <div className='profile-settings'>
            <button className='text-[#ced5e3] flex items-center hover:text-[#778395]' aria-describedby={id} onClick={onProfileButtonClick}>
              <div className='font-mulish text-[15px] block w-[120px] overflow-hidden text-ellipsis whitespace-nowrap max-[767px]:hidden'>{fullName || ''}</div>
              <ArrowDropDownIcon sx={{ fontSize: '19px' }} />
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className='p-[10px]'>

                <Link to={`${ProjectUrl[0].url}#/my-profile`}>
                  <div className='hover:bg-[#262b36] hover:text-[#9fa8bc] gap-x-[8px] flex items-center font-mulish tracking-[0.25px] h-[36px] px-[10px] text-[rgb(80,91,114)] text-[14px]'>
                    <PersonIcon sx={{ fontSize: '18px' }} />
                    <span>My Profile</span>
                  </div>
                </Link>

                <Link to={`${ProjectUrl[0].url}#/change-password`}>
                  <div className='hover:bg-[#262b36] hover:text-[#9fa8bc] gap-x-[8px] flex items-center font-mulish tracking-[0.25px] h-[36px] px-[10px] text-[rgb(80,91,114)] text-[14px]'>
                    <SettingsIcon sx={{ fontSize: '18px' }} />
                    <span>Change Password</span>
                  </div>
                </Link>

                <div onClick={(e) => onLogout()} className='hover:bg-[#262b36] hover:text-[#9fa8bc] gap-x-[8px] flex items-center font-mulish tracking-[0.25px] h-[36px] px-[10px] text-[rgb(80,91,114)] text-[14px] cursor-pointer'>
                  <LogoutIcon sx={{ fontSize: '18px' }} />
                  <span>Log Out</span>
                </div>

              </div>
            </Popover>
          </div>
        </div>

      </div>
    </div>

  )
}