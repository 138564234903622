import { faEnvelopesBulk, faPlus, faShieldHalved, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Formik } from "formik";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import ReactSelect, { components } from "react-select";
import { Tab } from '@headlessui/react';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PostCategory() {
    const DescriptionOptions = [
        { value: "CK Editor", label: "CK Editor" },
        { value: "Ritch Text Box", label: "Ritch Text Box" }
    ];

    const DateFormOptions = [
        { value: "Event Date", label: "Event Date" },
        { value: "Event Start & End Date", label: "Event Start & End Date" },
        { value: "Event Start & End Date with Time", label: "Event Start & End Date with Time" }
    ];

    const fileOptions = [
        { value: "Image", label: "Image" },
        { value: "Video", label: "Video" },
        { value: "Audio", label: "Audio" },
        { value: "PDF", label: "PDF" },
        { value: "Doc", label: "Doc" },
        { value: "Excel", label: "Excel" },
        { value: "Zip", label: "Zip" }
    ];

    const imageOptions = [
        { value: "JPG", label: "JPG" },
        { value: "PNG", label: "PNG" },
        { value: "GIF", label: "GIF" },
        { value: "JPEG", label: "JPEG" },
        { value: "SCG", label: "SCG" }
    ];

    const videoOptions = [
        { value: "MP4", label: "MP4" }
    ];

    const audioOptions = [
        { value: "MP3", label: "MP3" }
    ];

    const pdfOptions = [
        { value: "PDF", label: "PDF" }
    ];

    const docOptions = [
        { value: "Doc", label: "Doc" }
    ];

    const excelOptions = [
        { value: "Xlsx", label: "Xlsx" }
    ];

    const zipOptions = [
        { value: "ZIP", label: "ZIP" }
    ];

    const [selectedDescripti, setDescription] = useState({ value: "Description", label: "Description" });

    const [selectedDateForm, setDateForm] = useState({ value: "Date Formate", label: "Date Formate" });

    const [selectedFile, setFile] = useState({ value: "Image", label: "Image" });

    const [selectedImage, setImage] = useState({ value: "JPG", label: "JPG" });

    const [selectedVideo, setVideo] = useState({ value: "MP4", label: "MP4" });

    const [selectedAudio, setAudio] = useState({ value: "MP3", label: "MP3" });

    const [selectedPdf, setPdf] = useState({ value: "PDF", label: "PDF" });

    const [selectedDoc, setDoc] = useState({ value: "Doc", label: "Doc" });

    const [selectedExcel, setExcel] = useState({ value: "Xlsx", label: "Xlsx" });

    const [selectedZip, setZip] = useState({ value: "ZIP", label: "ZIP" });

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };



    // const Option1 = (props) => {
    //     return (
    //         <div>
    //             <components.Option1 {...props}>
    //                 <input
    //                     type="checkbox"
    //                     checked={props.isSelected}
    //                     onChange={() => null}
    //                 />{" "}
    //                 <label>{props.label}</label>
    //             </components.Option1>
    //         </div>
    //     );
    // };


    const [enabled, setEnabled] = useState(false)
    const formik = useFormik({
        initialValues: {
            //   file: '',
            //   imageWidth: 350,
            cname: '',
            size: '',
            note: '',
            customTextbox: '',

            //   titleColor: '#ff0000',
            //   redirectUrl: '',
            serialNumber: ''
        },
        validateOnMount: true,
        validate: (values) => {
            const errors = {};
            //   if ((showModal.mode === 'add' && !values.file) || (showModal.mode === 'edit' && !uploadedImageSrc && !values.file)) {
            //     errors.file = 'Required';
            //   }

            //   if (values.imageWidth && values.imageWidth < 350) {
            //     errors.file = 'Image width should be minimum of 350px';
            //   }

            if (values.cname && values.cname.length > 200) {
                errors.title = 'Maximum 200 characters are allowed';
            }

            //   const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
            //   if (values.redirectUrl && !urlPattern.test(values.redirectUrl)) {
            //     errors.redirectUrl = 'Invalid URL'
            //   } else if (values.redirectUrl && values.redirectUrl.length > 400) {
            //     errors.redirectUrl = 'Maximum 400 characters are allowed';
            //   }

            if (!values.serialNumber) {
                errors.serialNumber = 'Required';
            } else if (values.serialNumber && isNaN(Number(values.serialNumber))) {
                errors.serialNumber = 'Invalid serial number'
            } else if (values.serialNumber && values.serialNumber.length > 50) {
                errors.serialNumber = 'Maximum 50 characters are allowed'
            } else if (values.serialNumber < 0) {
                errors.serialNumber = 'Negative value is not allowed'

            }
            return errors;
        },
        // onSubmit: async (values) => {
        //   if (formik.isValid) {
        //     setPopupOverlay(true);
        //     await uploadImageToDrive();
        //   }
        // }
    });
    const sanitizeSerialNumber = (event) => {
        // const sanitizedValue = (event.target.value ?? '').replace(/[^0-9]/g, '');
        const sanitizedValue = (event.target.value ?? '').replace(/[^\d.]|\.(?=.*\.)/g, '');
        formik.setFieldValue('serialNumber', sanitizedValue);
    };

    let [Tabs] = useState([

        {

            title: 'Images',
            jsx: (<div className="">
                <div className="border border-[#999999] rounded  py-[6px] px-2 bg-white">
                    <div className="mb-2">
                        <label>Image: </label>
                    </div>
                    <div className="mb-3">
                        <ReactSelect className="text-[14px]"
                            options={imageOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedFile={false}
                            components={{
                                Option
                            }}
                            onChange={setImage}
                            allowSelectAll={true}
                            value={selectedImage}
                        />
                    </div>
                    <div className="flex items-center">
                        <div className="relative w-[100%]">
                            <input
                                className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                id="size"
                                type="text"
                                placeholder="Size (kb)"
                                name="size"
                                value={formik.values.size}
                            />
                            <label
                                htmlFor="size"
                                className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                            >
                                Size
                            </label>
                        </div>
                        <span className="text-[#646464] text-[14px] ms-2">KB</span>
                    </div>

                    <div className="mb-1 relative mt-[20px]">
                        <input
                            className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                            id="note"
                            type="text"
                            placeholder="Note"
                            name="note"
                            value={formik.values.note}
                        />
                        <label
                            htmlFor="note"
                            className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                        >
                            Note
                        </label>
                    </div>
                </div>
            </div>)
        },

        {

            title: 'Video',
            jsx: (<div className="">
                <div className="bg-white">
                    <div className="border border-[#999999] rounded  py-[6px] px-2">
                        <div className="mb-2">
                            <label>Video: </label>
                        </div>
                        <div className="mb-3">
                            <ReactSelect className="text-[14px]"
                                options={videoOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedFile={false}
                                components={{
                                    Option
                                }}
                                onChange={setVideo}
                                allowSelectAll={true}
                                value={selectedVideo}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="relative w-[100%]">
                                <input
                                    className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                    id="size"
                                    type="text"
                                    placeholder="Size (kb)"
                                    name="size"
                                    value={formik.values.size}
                                />
                                <label
                                    htmlFor="size"
                                    className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                >
                                    Size
                                </label>
                            </div>
                            <span className="text-[#646464] text-[14px] ms-2">KB</span>
                        </div>
                        <div className="mb-1 mt-[20px] relative">
                            <input
                                className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                id="note"
                                type="text"
                                placeholder="Note"
                                name="note"
                                value={formik.values.note}
                            />
                            <label
                                htmlFor="note"
                                className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                            >
                                Note
                            </label>
                        </div>
                    </div>
                </div>
            </div>)
        },

        {

            title: 'Audio',
            jsx: (<div className="">
                <div className="bg-white">
                    <div className="border border-[#999999] rounded  py-[6px] px-2">
                        <div className="mb-2">
                            <label>Audio: </label>
                        </div>
                        <div className="mb-3">
                            <ReactSelect className="text-[14px]"
                                options={audioOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedFile={false}
                                components={{
                                    Option
                                }}
                                onChange={setAudio}
                                allowSelectAll={true}
                                value={selectedAudio}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="relative w-[100%]">
                                <input
                                    className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                    id="size"
                                    type="text"
                                    placeholder="Size (kb)"
                                    name="size"
                                    value={formik.values.size}
                                />
                                <label
                                    htmlFor="size"
                                    className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                >
                                    Size
                                </label>
                            </div>
                            <span className="text-[#646464] text-[14px] ms-2">KB</span>
                        </div>
                        <div className="mb-1 relative mt-[20px]">
                            <input
                                className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                id="note"
                                type="text"
                                placeholder="Note"
                                name="note"
                                value={formik.values.note}
                            />
                            <label
                                htmlFor="note"
                                className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                            >
                                Note
                            </label>
                        </div>
                    </div>
                </div>
            </div>)
        },

        {

            title: 'PDF',
            jsx: (<div className="">
                <div className="bg-white">
                    <div className="border border-[#999999] rounded  py-[6px] px-2">
                        <div className="mb-2">
                            <label>PDF: </label>
                        </div>
                        <div className="mb-3">
                            <ReactSelect className="text-[14px]"
                                options={pdfOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedFile={false}
                                components={{
                                    Option
                                }}
                                onChange={setPdf}
                                allowSelectAll={true}
                                value={selectedPdf}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="relative w-[100%]">
                                <input
                                    className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                    id="size"
                                    type="text"
                                    placeholder="Size (kb)"
                                    name="size"
                                    value={formik.values.size}
                                />
                                <label
                                    htmlFor="size"
                                    className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                >
                                    Size
                                </label>
                            </div>
                            <span className="text-[#646464] text-[14px] ms-2">KB</span>
                        </div>
                        <div className="mb-1 mt-[20px] relative">
                            <input
                                className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                id="note"
                                type="text"
                                placeholder="Note"
                                name="note"
                                value={formik.values.note}
                            />
                            <label
                                htmlFor="note"
                                className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                            >
                                Note
                            </label>
                        </div>
                    </div>
                </div>
            </div>)
        },

        {

            title: 'Doc',
            jsx: (<div className="">
                <div className="bg-white">
                    <div className="border border-[#999999] rounded  py-[6px] px-2">
                        <div className="mb-2">
                            <label>Doc: </label>
                        </div>
                        <div className="mb-3">
                            <ReactSelect className="text-[14px]"
                                options={docOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedFile={false}
                                components={{
                                    Option
                                }}
                                onChange={setDoc}
                                allowSelectAll={true}
                                value={selectedDoc}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="relative w-[100%]">
                                <input
                                    className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                    id="size"
                                    type="text"
                                    placeholder="Size (kb)"
                                    name="size"
                                    value={formik.values.size}
                                />
                                <label
                                    htmlFor="size"
                                    className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                >
                                    Size
                                </label>
                            </div>
                            <span className="text-[#646464] text-[14px] ms-2">KB</span>
                        </div>
                        <div className="mb-1 mt-[20px] relative">
                            <input
                                className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                id="note"
                                type="text"
                                placeholder="Note"
                                name="note"
                                value={formik.values.note}
                            />
                            <label
                                htmlFor="note"
                                className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                            >
                                Note
                            </label>
                        </div>
                    </div>
                </div>
            </div>)
        },

        {

            title: 'Excel',
            jsx: (<div className="">
                <div className="bg-white">
                    <div className="border border-[#999999] rounded  py-[6px] px-2">
                        <div className="mb-2">
                            <label>Excel: </label>
                        </div>
                        <div className="mb-3">
                            <ReactSelect className="text-[14px]"
                                options={excelOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedFile={false}
                                components={{
                                    Option
                                }}
                                onChange={setExcel}
                                allowSelectAll={true}
                                value={selectedExcel}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="relative w-[100%]">
                                <input
                                    className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                    id="size"
                                    type="text"
                                    placeholder="Size (kb)"
                                    name="size"
                                    value={formik.values.size}
                                />
                                <label
                                    htmlFor="size"
                                    className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                >
                                    Size
                                </label>
                            </div>
                            <span className="text-[#646464] text-[14px] ms-2">KB</span>
                        </div>
                        <div className="mb-1 mt-[20px] relative">
                            <input
                                className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                id="note"
                                type="text"
                                placeholder="Note"
                                name="note"
                                value={formik.values.note}
                            />
                            <label
                                htmlFor="note"
                                className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                            >
                                Note
                            </label>
                        </div>
                    </div>
                </div>
            </div>)
        },

        {

            title: 'Zip',
            jsx: (<div className="">
                <div className="bg-white">
                    <div className="border border-[#999999] rounded  py-[6px] px-2 bg-white">
                        <div className="mb-2">
                            <label>Zip: </label>
                        </div>
                        <div className="mb-3">
                            <ReactSelect className="text-[14px]"
                                options={zipOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedFile={false}
                                components={{
                                    Option
                                }}
                                onChange={setZip}
                                allowSelectAll={true}
                                value={selectedZip}
                            />
                        </div>
                        <div className="flex items-center">
                            <div className="relative w-[100%]">
                                <input
                                    className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                    id="size"
                                    type="text"
                                    placeholder="Size (kb)"
                                    name="size"
                                    value={formik.values.size}
                                />
                                <label
                                    htmlFor="size"
                                    className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                >
                                    Size
                                </label>
                            </div>
                            <span className="text-[#646464] text-[14px] ms-2">KB</span>
                        </div>
                        <div className="mt-[20px] mb-1 relative">
                            <input
                                className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                id="note"
                                type="text"
                                placeholder="Note"
                                name="note"
                                value={formik.values.note}
                            />
                            <label
                                htmlFor="note"
                                className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                            >
                                Note
                            </label>
                        </div>
                    </div>
                </div>
            </div>)
        },




    ]);

    useEffect(() => {
        document.title = 'Post Category - Zibma Infotech';
    }, []);
    return (
        <>
            <div className="h-full relative overflow-hidden bg-[#D8DCE3] py-[9px]">
                <div className={`flex px-[10px] gap-x-[5px] text-[#696c74] text-[12px] h-[30px] items-center bg-white mb-[5px]`}>
                    <FontAwesomeIcon className="pb-[2px] text-[14px]" icon={faEnvelopesBulk} />
                    <div className={`font-bold font-mulish tracking-[0.25px] capitalize`}>Post Category</div>
                </div>




                <div className="flex flex-col px-[10px] pt-[15px] bg-white h-[calc(100%-35px)] overflow-auto">
                    <div className='max-w-[700px] w-full mt-[10px]'>
                        <form>
                            <div className="px-[15px] py-[5px]">

                                <div className="mb-[20px] relative">
                                    <input
                                        className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                        id="categoriesName"
                                        type="text"
                                        placeholder="Categories Name"
                                        name="CategoriesName"
                                        onChange={Formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        value={formik.values.title}
                                    />
                                    <label
                                        htmlFor="cname"
                                        className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                    >
                                        Categories Name <span className="text-[#dc3545] text-[16px] leading-[18px]">*</span>
                                    </label>
                                    <div className="text-[#dc3545] text-[12px] mt-[2px] ml-[4px]">
                                        {formik.errors.cname && formik.touched.title && formik.errors.cname}
                                    </div>
                                </div>

                                <div className="mb-[20px] relative">
                                    <input
                                        className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                        id="serialNumber1"
                                        type="text"
                                        placeholder="Serial No."
                                        name="serialNumber"
                                        onChange={(e) => sanitizeSerialNumber(e)}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.serialNumber}
                                    />
                                    <label
                                        htmlFor="serialNumber1"
                                        className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                    >
                                        Serial No. <span className="text-[#dc3545] text-[16px] leading-[18px]">*</span>
                                    </label>
                                    <div className="text-[#dc3545] text-[12px] mt-[2px] ml-[4px]">
                                        {formik.errors.serialNumber && formik.touched.serialNumber && formik.errors.serialNumber}
                                    </div>
                                </div>
                                <div className="mb-[20px] bg-[#fafaf8] border rounded p-2">
                                    <div className="flex gap-[70px]">
                                        <div className="">
                                            <label className="font-medium">Title: </label>
                                        </div>
                                        <div className="flex">
                                            <div>
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable notifications</span>
                                                    <span
                                                        className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>
                                                <span className="text-[#888686] ms-2 font-normal">Visible</span>
                                            </div>
                                            <div className="ms-6">
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable notifications</span>
                                                    <span
                                                        className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>
                                                <span className="text-[#888686] ms-2 font-normale">Compulsory </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-[20px] bg-[#fafaf8] border rounded p-2">
                                    <div className="flex gap-[20px]">
                                        <div className="mb-2">
                                            <label className="font-medium">Description: </label>
                                        </div>
                                        <div className="flex mb-[30px]">
                                            <div>
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable notifications</span>
                                                    <span
                                                        className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>
                                                <span className="text-[#888686] ms-2 font-normale">Visible</span>
                                            </div>
                                            <div className="ms-6">
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable notifications</span>
                                                    <span
                                                        className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>
                                                <span className="text-[#888686] ms-2 font-normale">Compulsory </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div>
                                            <ReactSelect className="text-[14px]"
                                                options={DescriptionOptions}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={setDescription}
                                                allowSelectAll={true}
                                                value={selectedDescripti}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-[20px]">
                                    <div className="bg-[#fafaf8] border rounded p-2 mb-[20px]">
                                        <div className="flex gap-[80px]">
                                            <div className="mb-2">
                                                <label className="font-medium">File: </label>
                                            </div>
                                            <div className="flex mb-[30px]">
                                                <div>
                                                    <Switch
                                                        checked={enabled}
                                                        onChange={setEnabled}
                                                        className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                            } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                    >
                                                        <span className="sr-only">Enable notifications</span>
                                                        <span
                                                            className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                                } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                        />
                                                    </Switch>
                                                    <span className="text-[#888686] ms-2 font-normale">Visible</span>
                                                </div>
                                                <div className="ms-6">
                                                    <Switch
                                                        checked={enabled}
                                                        onChange={setEnabled}
                                                        className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                            } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                    >
                                                        <span className="sr-only">Enable notifications</span>
                                                        <span
                                                            className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                                } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                        />
                                                    </Switch>
                                                    <span className="text-[#888686] ms-2 font-normale">Compulsory </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div>
                                                <ReactSelect className="text-[14px]"
                                                    options={fileOptions}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedFile={false}
                                                    components={{
                                                        Option
                                                    }}
                                                    onChange={setFile}
                                                    allowSelectAll={true}
                                                    value={selectedFile}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <Tab.Group>
                                        <Tab.List className="flex space-x-1 rounded bg-[#eeeeee] p-1">
                                            {(Tabs).map((_tab) => (
                                                <Tab
                                                    key={_tab}
                                                    className={({ selected }) =>
                                                        classNames(
                                                            'w-full rounded-lg py-2.5 text-sm font-medium leading-4 text-[#2574ab]',
                                                            'ring-opacity-60 ring-offset-2 ring-offset-slate-900 focus:outline-none focus:ring-2',
                                                            selected
                                                                ? 'bg-[#f7f6f6] shadow'
                                                                : 'text-slate-900 hover:bg-white/[0.25] hover:text-[#2574ab]'
                                                        )
                                                    }
                                                >
                                                    {_tab.title}
                                                </Tab>
                                            ))}
                                        </Tab.List>
                                        <Tab.Panels className="bg-[#fafaf8]">
                                            {(Tabs).map((_tab, idx) => (
                                                <Tab.Panel
                                                    key={idx}
                                                    className={classNames(
                                                        'rounded-xl  p-3',
                                                        'ring-opacity-60 ring-offset-2 ring-offset-slate-900 focus:outline-none focus:ring-2'
                                                    )}
                                                >
                                                    {_tab.jsx}
                                                </Tab.Panel>
                                            ))}
                                        </Tab.Panels>
                                    </Tab.Group>

                                </div>
                                <div className="mb-[20px] bg-[#fafaf8] border rounded p-2">
                                    <div className="flex gap-[16px]">
                                        <div className="mb-2">
                                            <label className="font-medium">Date Format: </label>
                                        </div>
                                        <div className="flex mb-[20px]">
                                            <div>
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable notifications</span>
                                                    <span
                                                        className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>
                                                <span className="text-[#888686] ms-2 font-normal">Visible</span>
                                            </div>
                                            <div className="ms-6">
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable notifications</span>
                                                    <span
                                                        className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>
                                                <span className="text-[#888686] ms-2 font-normal">Compulsory </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div>
                                            <ReactSelect className="text-[14px]"
                                                options={DateFormOptions}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={setDateForm}
                                                allowSelectAll={true}
                                                value={selectedDateForm}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-[20px] bg-[#fafaf8] border rounded p-2">
                                    <div className="flex gap-[78px]">
                                        <div className="">
                                            <label className="font-medium">URL: </label>
                                        </div>
                                        <div className="flex">
                                            <div>
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable notifications</span>
                                                    <span
                                                        className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>
                                                <span className="text-[#888686] ms-2 font-normal">Visible</span>
                                            </div>
                                            <div className="ms-6">
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-[#257EAB]' : 'bg-gray-200'
                                                        } relative inline-flex h-5 w-10 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable notifications</span>
                                                    <span
                                                        className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                                                    />
                                                </Switch>
                                                <span className="text-[#888686] ms-2 font-normale">Compulsory </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-[#fafaf8] border rounded p-2 mb-[20px]">
                                    <div className="mb-[20px]">
                                        <label className="font-medium">Custom Text box: </label>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="w-[97%]">
                                            <div>
                                                <div className="relative">
                                                    <input
                                                        className="peer font-mulish text-[14px] h-[40px] px-[12px] w-full border border-solid border-[#bdc3d1] rounded-[3.75px] placeholder-transparent focus:outline-none"
                                                        id="CustomTextbox"
                                                        type="text"
                                                        placeholder="CustomTextbox"
                                                        name="customTextbox"
                                                        value={formik.values.customTextbox}
                                                    />
                                                    <label
                                                        htmlFor="note"
                                                        className="absolute m-0 px-[3px] text-[13px] top-[-9px] bg-white font-mulish left-[12px] text-[#696c74] transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:top-[10px] peer-focus:top-[-9px] peer-focus:text-[13px] peer-focus:bg-white"
                                                    >
                                                        CustomTextbox
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <FontAwesomeIcon icon={faX} className="text-[#dc3545] text-xs" />
                                        </div>
                                    </div>
                                    <div className=" mt-[20px]">
                                        <button className="py-1 px-2 rounded bg-[#E6E6E6] hover:bg-[#d4d4d4]">
                                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div className="h-[65px] flex items-center justify-end mt-[5px]">
                                        <div className="text-[14px] font-mulish font-semibold pr-[15px] flex gap-x-[10px]">
                                            <button className="hover:opacity-[0.88] rounded-[4px] h-[34px] w-[65px] text-black bg-[#f6f0f6]">Cancel</button>
                                            <button type="submit" className={`hover:opacity-[0.88] rounded-[4px] h-[34px] w-[65px] text-white bg-gradient-to-r from-[#259dab] to-[#2574ab]`}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="px-[15px] font-mulish font-semibold flex w-full">
                                <button type="submit" className={`${formik.isValid ? 'cursor-pointer' : 'cursor-not-allowed'} hover:opacity-[0.88] mt-[10px] mb-[30px] rounded-[4px] h-[40px] w-[200px] text-[15px] text-white bg-gradient-to-r from-[#259dab] to-[#2574ab]`}>Save</button>
                            </div> */}
                        </form>
                    </div>


                </div>
            </div>
        </>
    )

}