export const ProjectUrl = [
  { name: 'account', url: 'https://sandbox-account.zibma.com/#/', active: true },
  { name: 'biomatric', url: 'https://sandbox-biometric.zibma.com/#/', active: false },
  { name: 'ticket', url: 'https://sandbox-ticket.zibma.com/#/', active: false },
  { name: 'display_site', url: 'https://sandbox-display.zibma.com/', active: true },
  { name: 'active_reports', url: 'https://sandbox-active-report.zibma.com/', active: true }
];



export const youtubeThumbUrl = 'http://i4.ytimg.com/vi';

export const REACT_APP_ACCOUNT_API_URL="https://api-sandbox-account.zibma.com/account/"
//  REACT_APP_ACCOUNT_API_URL=https://192.168.5.200:15201/account/

export const REACT_APP_DRIVE_URL="https://api-sandbox-drive.zibma.com/drive"
//  REACT_APP_DRIVE_URL=https://192.168.5.200:15204/


export const REACT_APP_API_URL="https://api-sandbox-display.zibma.com/display_site/"
//  REACT_APP_API_URL=https:/192.168.5.200:15206/display_site/


