
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { lazy, Suspense, useState, createContext } from 'react';
import MainHeader from './Layout/MainHeader';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Blocks, Dna } from 'react-loader-spinner';
import { SidebarContext } from './Utils/contexts';
import PostCategory from './Pages/post-category/PostCategory';
import Review from './Pages/review/Review';



const SocialAccounts = lazy(() => import("./Pages/social-accounts/SocialAccounts"));
const Dashboard = lazy(() => import("./Pages/dashboard/Dashboard"))
const ContactUs = lazy(() => import("./Pages/contact-us/ContactUs"))
const GalleryAlbum = lazy(() => import("./Pages/gallery-album/GalleryAlbum"))
const HomePageSlider = lazy(() => import("./Pages/home-page-slider/HomePageSliders"))
const Navbar = lazy(() => import("./Layout/Navbar"))
const Post = lazy(() => import("./Pages/post/Post"))
const PostCategories = lazy(() => import("./Pages/post-categories/PostCategories"))
const Testimonial = lazy(() => import("./Pages/testimonial/Testimonial"))
const HomePagePopup = lazy(() => import("./Pages/home-page-popup/HomePagePopup"))
const Page = lazy(() => import("./Pages/page/Page"))
const Menus = lazy(() => import("./Pages/menus/Menus"))
const Organizations = lazy(() => import("./Pages/organizations/Organizations"))

const VideoLibrary = lazy(() => import("./Pages/video-library/VideoLibrary"))

const Videos = lazy(() => import("./Pages/video-library/videos/Videos"))
const DownloadSectionGroup = lazy(() => import("./Pages/download-section-group/DownloadSectionGroup"))
const ApiDocument = lazy(() => import("./Pages/api-documentation/ApiDocument"))
const DownloadSection = lazy(() => import("./Pages/download-section-group/download-section/DownloadSection"))
const PostNew = lazy(() => import("./Pages/post-new/PostNew"))

function App() {

  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => {
    setShowSidebar((current) => !current);
  };
  return (


    <BrowserRouter>
      <SidebarContext.Provider value={showSidebar}>
        <div className="flex flex-col bg-[#d8dce3] h-max min-h-screen ">
          <MainHeader isSidebarOpen={showSidebar} toggleSidebar={toggleSidebar} />



          <div className='flex gap-2 h-[calc(100vh-50px)]'>
            <Navbar showSidebar={showSidebar} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Suspense fallback={

                <div className='h-full main-loader w-full flex flex-col justify-center items-center bg-white'>
                  <Dna
                    visible={true}
                    height="88"
                    width="88"
                    ariaLabel="dna-loading"
                    wrapperStyle={{ color: "black" }}
                    wrapperClass="dna-wrapper"
                  />
                  <h1 className='animate__animated  animate__fadeIn text-[16px] select-none '>Loading...</h1>
                </div>}>
                <div className={`${showSidebar ? 'w-[calc(100%-236px)]' : 'w-[calc(100vw-16px)]'} transition-[all] duration-[250ms]`}>
                  <Routes>

                    <Route path='/' element={<Dashboard />} />

                    <Route path='/dashboard' element={<Dashboard />} />

                    <Route path='/contact-us' element={<ContactUs />} />

                    <Route path='/home-page-slider' element={<HomePageSlider />} />

                    <Route path='//home-page-popup' element={<HomePagePopup />} />

                    <Route path='/gallery-album' element={<GalleryAlbum />} />

                    <Route path='/post' element={<Post />} />

                    <Route path='/post-categories' element={<PostCategories />} />

                    <Route path='/page' element={<Page />} />

                    <Route path='/menus' element={<Menus />} />

                    {/* <Route path='/menus/*' element={<Submenu />} /> */}

                    <Route path='/testimonial' element={<Testimonial />} />

                    <Route path='/video-library' element={<VideoLibrary />} />

                    <Route path='/video-library/:libraryName' element={<Videos />} />

                    <Route path='/download-section-group' element={<DownloadSectionGroup />} />

                    <Route path='/download-section-group/:groupName' element={<DownloadSection />} />

                    <Route path="/social-accounts" element={<SocialAccounts />} />

                    <Route path="/organizations" element={<Organizations />} />

                    <Route path="/api-documentation" element={<ApiDocument />} />

                    <Route path="/post-category" element={<PostCategory />} />

                    <Route path="/post-new" element={<PostNew />} />

                    <Route path="/review" element={<Review />} />

                    <Route path='*' element={<h1>Not Found</h1>} />

                  </Routes>
                </div>

              </Suspense>
            </LocalizationProvider>
          </div>



        </div>
      </SidebarContext.Provider>
      
    </BrowserRouter>

  );
}

export default App;
